import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Baryonyx from "../../images/baryonyx.gif";
import PenScenery from "../../images/pen-scenery.gif";
import Smith from "../../images/smith.gif";
import LizRun from "../../images/liz-run.gif";

export default function GiantsChalice() {
  return (
    <Layout>
      <Helmet>
        <title>Giant's Chalice | Nathan Pasko</title>
        <meta name="description" content="Hack and slash your way through a janky, retro 3D fantasy world in Giant's Chalice, available for Mac, Windows, and Linux. Inspired by noise, knights, and the PlayStation era, this off-kilter adventure features a responsive soundtrack and superflat, pixelated graphics." />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
        <li>
            <picture>
              <img
                src={Baryonyx}
                alt=""
                height="590"
                width="792"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src={PenScenery}
                alt=""
                height="596"
                width="792"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src={Smith}
                alt=""
                height="596"
                width="792"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img
                src={LizRun}
                alt=""
                height="596"
                width="792"
              />
            </picture>
          </li>
        </ul>
        <h1>Giant's Chalice</h1>
        <p>
          Giant's Chalice is a janky fantasy adventure game with a nostalgic
          tone. Explore a colorful and dangerous world where humankind is
          beseiged by monsters. Collect hundreds of gems and use them to level
          up and gain stats. More than 45 different weapons are hidden across
          the world, guarded by ferocious and territorial monsters.
        </p>
        <p>
          For this game I used a PlayStation-like shader and low-poly models to
          restrict the visual fidelity and invoke the imagination.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/272751?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/giants-chalice">Giant's Chalice by A.V. Knights</a></iframe>      </div>
    </Layout>
  );
}
